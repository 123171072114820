<template>
    <div class="screenContainer">
        <img src="../../../assets/zhiboBack.png" class="backImg"/>

        <div class="time">
            剩余时间 {{itemData.countDownTimes?itemData.countDownTimes:getFormatDuringTime(0)}}
        </div>

        <video :src="videoUrl" controls="control" controlsList="nodownload noplaybackrate" disablePictureInPicture class="videoClass"></video>

        <!-- <div class="close" @click="close"></div> -->
        <!-- <div class="screenContent">
            <div class="screenLeft">
                <div id="main"></div>
            </div>
            <div class="screenRight">
                <div class="chatBoard">
                    <div v-for="(item,index) in messageContainer" :key="index" class="messageItem">
                        <div class="messageUser">{{userNamesMap[item.userId]['name']}}：<span class="messageContent">{{item.data}}</span></div>
                    </div>
                </div>
                <div class="input">
                    <el-input type="textarea" v-model="editMessage" placeholder="Please input the message and press enter to send it" resize="none" @keydown.enter.native="sendMessage"></el-input>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import * as QNRTC from 'pili-rtc-web'
import { createRoomToken,getUserNames ,getResidueTime } from '../../../api/index'
import { getUserId,getProjectCode ,getSessionUrl ,getSessionName} from '../../../utils/store'

const myRoom = new QNRTC.TrackModeSession()

export default {
  name:'VideoPlay',
    data () {
        return {
            audioDevice: '',
            videoDevice: '',
            audioDevices: [],
            videoDevices: [],
            roomToken: '',
            userList: [],
            roomId:'',
            messageContainer:[],
            editMessage:'',
            userNamesMap:{},
            roomId:this.$route.query.roomId,
            event_name:getSessionName(),
            videoTime:this.$route.query.videoTime,
            videoUrl:getSessionUrl(),
            itemData:{}
        }
    },
    mounted () {
        document.title = 'Lecture'
        // console.log(111111,getSessionUrl())
        this.videoUrl = getSessionUrl()
        this.event_name = getSessionName()
        // this.getUserNames()
        // this.joinRoom()
        this.getResidueTime()
    },
   
    methods: {
        close(){
            window.close()
        },
        getUserNames(){
            // getUserNames(getProjectCode()).then(res=>{
            //     if(res.data.code===0){
            //         this.userNamesMap = res.data.data
            //     }
            // })
        },
        getResidueTime(){


            // console.log(1111,this.event_name,this.videoTime)

            getResidueTime(getProjectCode(),getUserId(),this.event_name,this.videoTime).then(res=>{
                if(res.data.code===0){


                    this.handleRestTime(res.data.data)

                    this.itemData = res.data.data
                }else{
                    window.close()
                }
            })

            

        },

        getFormatDuringTime(during) {
            var s = Math.floor(during / 1) % 60;
            during = Math.floor(during / 60);
            var i = during % 60;
            during = Math.floor(during / 60);
            var h = during;
            return  h + ':' + i + ':' + s ;
        },
        handleRestTime(item){
            let totalTime = item.have_time
            item.countDownThread = window.setInterval(() => {
                this.$set(item,'countDownTimes',this.getFormatDuringTime(totalTime))
                totalTime--
                if (totalTime < 0) {
                    window.close()
                }
                console.log(this.event_rooms)
            }, 1000)
        }
        // async joinRoom () {
        //     let roomToken
        //     const result = await this.createRoomToken()
        //     if(result.code==0){
        //         roomToken = result.data.token
        //     }else{
        //         this.$message(result.msg)
        //         return;
        //     }
        //     try {
        //         // 加入房间
        //         await myRoom.joinRoomWithToken(roomToken)
        //         this.$message({
        //             message:'Join the room successful',
        //             type:'success'
        //         })
        //         // 如果加入房间后发现房间已经有人发布，就订阅他
        //         this.subscribeTracks(myRoom.trackInfoList)
        //     } catch (e) {
        //         console.error(e)
        //         this.$message(`Join room failed！ErrorCode: ${e.code || ''}`)
        //         return
        //     }
        //     myRoom.on("track-add", async(tracks) => {
        //         this.subscribeTracks(tracks);
        //     });
        //     myRoom.on("messages-received", msgData => {
        //         this.messageContainer = [...this.messageContainer,...msgData]
        //     })
        // },

        // async subscribeTracks (trackInfoList) {
        //     let subscribedVideo = myRoom.subscribedTracks.filter(item=>item.info.kind==='video')
          
        //     await myRoom.unsubscribe(subscribedVideo.map(t => t.trackId))
        //     let videos = document.getElementsByTagName('video')
  
        //     for(let i of videos){
        //         i.remove()
        //     }
        //     let infoList = trackInfoList.filter(item=>item.tag!=='employee')
        //     myRoom.subscribe(infoList.map(t => t.trackId)).then(async(tracks) => {
        //         await myRoom.unsubscribe(myRoom.subscribedTracks.map(t => t.trackId))
        //         for (const track of tracks) {
        //             let main = document.getElementById('main');
        //             track.play(main);
        //         }
        //     });
        //     this.subscribedTrackInfos = [...this.subscribedTrackInfos,...infoList]
        // },
        // createRoomToken () {
        //     return new Promise((resolve,reject)=>{
        //         createRoomToken(this.roomId,getUserId(),'user').then(res => {
        //             resolve(res.data)
        //         })
        //     })
        
        // },
        // listRoomUsers (e) {
        //     if(e){
        //         if(this.roomId!==''){
        //             listRoomUsers(this.room_id).then(res => {
        //                 console.log(res)
        //                 this.userList = res.data
        //             })
        //         }
        //     }
        // },
        // async sendMessage(){
        //     if(!this.editMessage){
        //         this.$message.warning('Please input the message and press enter to send it')
        //         return
        //     }
        //     try {
        //         await myRoom.sendCustomMessage(this.editMessage);
        //         let currentTime = new Date()
        //         let sendedMessage = {
        //             data:this.editMessage,
        //             timestamp:currentTime.getTime(),
        //             type:'normal',
        //             userId:getUserId()
        //         }
        //         this.messageContainer.push(sendedMessage)
        //         this.editMessage = ''
        //     } catch (e) {
        //         this.$message.warning(`发送失败，请先加入房间`)
        //     }
        // }
    }
}
</script>

<style lang="less">



.screenContainer{
    width: 100%;
    height: 100vh;
    display: flex;
    // align-items: center;
    // justify-content: center;
    overflow: hidden;


    .time{
        position: absolute;
        margin-top: 20px;
        width: 80vw;
        margin-left: 10vw;
        height: 50px;
        // background-color: red;
        line-height: 50px;
        color: white;
        font-size: 30px;
        text-align: center;
        font-weight: 900;
    }
    .backImg{
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
    }
    .videoClass{
        position: absolute;
        margin-left: 10%;
        margin-top: 5%;
        height: 80%;
        width: 80%;
    }
    .close{
        position: absolute;
        margin-left: 10px;
        width: 100px;
        height: 100px;
        background-color: rebeccapurple;
    }
   
}
.qnrtc-stream-player {
  width: 100% !important;
  /* height: 1080px !important; */
  background: #000;
}

</style>
